import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";


const state = {
    selectedPaddler: {},
    paddlerData: {
        addressOfEmployee: {},
        accountDetail: {}
    },
    updateMode: false,
    logInPaddler: {},
    selectedUnit: {},
    selectedDept: {},
    ribsCompanies: []
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};