export default {
    SET_SELECTED_PADDLER: (state, payload) => {
        console.log(payload);
        state.selectedPaddler = payload;
    },
    SET_SELECTED_UNIT: (state, payload) => {
        state.selectedUnit = payload;
    },
    SET_UPDATE_MODE(state, payload) {
        state.updateMode = payload;
    },
    SET_SELECTED_DEPT: (state, payload) => {
        state.selectedDept = payload;
    },
    SET_PADDLER_DATA: (state, payload) => {
        state.paddlerData = payload;
        console.log(payload);
        sessionStorage.setItem("currentPaddlerData", JSON.stringify(payload))
    },

    SET_LOGIN_PADDLER_DATA: (state, payload) => {
        state.logInPaddler = payload;
        sessionStorage.setItem("loginPaddlerData", JSON.stringify(payload))
    },
    SET_RIBS_COMPANIES: (state, payload) => {
        state.ribsCompanies = payload
    },
    TOGGLE_SUBSCRIBE_TO_RIBS_COMPANIES: (state, payload) => {
        state.ribsCompanies.forEach(company => {
            if (company.id === payload) {
                company.subscribed = !company.subscribed
            }
        })
    }

};