import Vue from "vue";
import Vuex from "vuex";
import snackBar from "./snackBar";
import menu from "./menu";
import paddler from "./paddler";
import marketing from "@/store/marketing";
import paymentLog from '@/store/paymentLog';
import walletStore from "@/store/walletStore";
import products from "@/store/products"
import booking from "@/store/booking"
import tripAndTravellerDetails from "@/store/tripAndTravellerDetails"
import companyProfile from "@/store/companyProfile"
import seatsSelection from "@/store/seatsSelection"
import selectedSeat from "@/store/selectedSeat"

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    snackBar,
    paddler,
    menu,
    marketing,
    paymentLog,
    walletStore,
    booking,
    products,
    tripAndTravellerDetails,
    companyProfile,
    seatsSelection,
    selectedSeat
  },
});