import {
    getPaddleByEmail
} from "../../services/api/APIService"
export default {
    setSelectedPaddler({
        commit
    }, payload) {
        commit("SET_SELECTED_PADDLER", payload);
    },
    setSelectedUnit({
        commit
    }, payload) {
        commit("SET_SELECTED_UNIT", payload)
    },
    setSelectedDept({
        commit
    }, payload) {
        commit("SET_SELECTED_DEPT", payload)
    },
    setPaddlerData({
        commit
    }, payload) {
        commit("SET_PADDLER_DATA", payload);
    },
    setRibsCompanies({
                       commit
                   }, payload) {
        commit("SET_RIBS_COMPANIES", payload);
    },
    toggleSubscribeToRibs({commit},payload){
        commit("TOGGLE_SUBSCRIBE_TO_RIBS_COMPANIES",payload)
    },
    async setLoginPaddlerData({
        commit
    }) {
        let loginPaddler = JSON.parse(sessionStorage.getItem("loginPaddlerData"))
        if (!loginPaddler) {
            let userData = JSON.parse(sessionStorage.getItem("userData"))
            if (userData) {
                let data = {}
                data.email = userData.email
                return await getPaddleByEmail(data).then(res => {
                    commit("SET_LOGIN_PADDLER_DATA", res.data);
                })
            }
        } else {
            commit("SET_LOGIN_PADDLER_DATA", loginPaddler);
        }
    },
    setUpdateMode({
        commit
    }, payload) {
        commit('SET_UPDATE_MODE', payload);
    },
};