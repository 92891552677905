<template>
  <v-alert
      dark
      :color="background_color"
      min-width="10vw"
      :value="showSnackBar"
      class="snackbar"
  >
    <div class="d-flex justify-center">
      <p class="mb-0 tw-mx-3 font-weight-bold snackFont">{{ snackBarMessage }}</p>
    </div>
  </v-alert>
</template>

<script>
import {mapActions} from "vuex";
export default {
  name: "SnackBar",
  data() {
    return {
      background_color: "red",
    };
  },

  props: {
    showSnackBar: {
      type: Boolean,
    },
    success: {
      type: Boolean,
    },
    snackBarMessage: {
      type: String,
    },
    alternateColor: String,
  },
  methods: {
    ...mapActions("snackBar", ["closeSnackBar"]),
    closeSnackbar() {
      this.closeSnackBar();
    },

    getSnackBarColor() {
      if (this.success === true) {
        return (this.background_color = "#0BCA7A");
      } else if (!this.success && this.alternateColor !== undefined) {
        return (this.background_color = "amber");
      } else {
        return (this.background_color = "red");
      }
    },
  },
  watch: {
    success: {
      handler: 'getSnackBarColor'
    }
  },
};
</script>

<style scoped>
.snackbar {
  padding: 12px !important;
  text-align: center !important;
  box-shadow: 0px 11px 20px rgba(130, 130, 130, 0.15) !important;
}
.snackFont{
  font-family: Inter, sans-serif!important;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #ffffff;
}
.v-alert {
  position: fixed;
  z-index: 1000;
  /*right: 3%;*/
  top: 1%;
  right: 0;
}

/*.mobile-snackbar {*/
/*  top: 3rem;*/
/*  left: 5% !important;*/
/*}*/
</style>
