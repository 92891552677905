export default {
    setSearchedPaymentId({
        commit
    }, paymentID) {
        commit('SET_SEARCHED_PAYMENT_ID', paymentID)
    },
    setSelectedPayment({
        commit
    }, payload) {
        commit('SET_SELECTED_PAYMENT', payload)
    }
};