import {isTokenExpired} from "./AuthApiService";

export default function authHeader() {
    let user = JSON.parse(sessionStorage.getItem('userToken'));

    if (user && user.access_token) {
        if (isTokenExpired(user.access_token)) {
            sessionStorage.removeItem('userToken');
            window.location.href = "/";
        }
        else {
            return {Authorization: `Bearer ${user.access_token}`};
        }
    } else {
        return {};
    }

}