import {
    getPendingTripsBySearchQuery
} from "@/services/api/APIService";
import {
    getTripbyId
} from "@/services/api/APIService";

export default {
    addSeat({
        commit
    }, payload) {
        commit("ADD_SEAT", payload);
    },
    removeSeat({
        commit
    }, payload) {
        commit("REMOVE_SEAT", payload);
    },
    setOneWayBookingMinutesTimer({
        commit
    }, payload) {
        commit('SET_ONE_WAY_BOOKING_MINUTES_TIMER', payload)
    },

    setOneWayBookingShowTimer({
        commit
    }, payload) {
        commit('SET_ONE_WAY_BOOKING_SHOW_TIMER', payload)
    },
    clearSeats({
        commit
    }) {
        commit("CLEAR_SEATS")
    },
    getTotalAmount({
        commit
    }, value) {
        commit("GET_TOTAL_AMOUNT", value)
    },
    updateCurrentSeats({
        commit
    }, payload) {
        commit("UPDATE_CURRENT_SEATS", payload)
    },
    updateTripAndTravellerDetails({
        commit
    }, payload) {
        commit("UPDATE_TRIP_AND_TRAVELLER_DETAILS", payload);
    },
    async setPendingTrips({
        commit
    }, payload) {
        return await getPendingTripsBySearchQuery(payload).then(res => {
                res.data.forEach(trip => {
                    if (trip.tripRegion === 'crossCountry') {
                        trip.tripCategories.forEach(category => category.selected = false)
                    }
                })
                commit("SET_PENDING_TRIPS", res.data);
                return Promise.resolve()
            },
            error => {
                return Promise.reject(error)
            })
    },
    setOneWayTrips({
        commit
    }, payload) {
        commit('SET_ONE_WAY_TRIPS', payload)
    },

    async setUserSelectedTrip({
        commit
    }, data) {
        return await getTripbyId(data).then(
            res => {
                commit("SET_USER_SELECTED_TRIP", res.data);
                sessionStorage.setItem("userSelectedTrip", JSON.stringify(res.data))
                return Promise.resolve()
            },
            error => {
                return Promise.reject(error)
            }
        )
    },
    setSingleBooking({
        commit
    }, payload) {
        commit("SET_SINGLE_BOOKING", payload);
    },
};