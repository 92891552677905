<template>
    <div id="app">
      <SnackBar
      :success="success"
      :show-snack-bar="showSnackBar"
      :snack-bar-message="snackBarMessage"
    />
    <router-view></router-view>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SnackBar from "./components/reuseables/SnackBar";
export default {
  name: "App",
  components: { SnackBar },
  data: () => ({
    //
  }),
  computed: {
    ...mapGetters("snackBar", ["showSnackBar", "success", "snackBarMessage"]),
  },
};
</script>
<style lang="scss">
* {
  font-family: "Inter", sans-serif;
}
.content {
  display: flex;
  flex-direction: column;
  width: 90%;
  @media screen and (min-width: 1048px) {
    max-width: 70%;
  }
}
.theme--light.v-app-bar.v-toolbar.v-sheet {
  background: none;
}
/* .blue-button{

} */
.phone {
  width: 233px;
  height: 289px;
  @media screen and (min-width: 1024px) {
    width: 668px;
    height: 780px;
  }
}
.headerText2 {
  font-family: ABeeZee,sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 44px;
  line-height: 52px;
  letter-spacing: 0.05em;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #004aad;
  color: rgba(0, 0, 0, 0.7);
  @media screen and (max-width: 1024px) {
    font-size: 32px;
    line-height: 38px;
  }
}
.headerTextMedium {
  font-family: ABeeZee;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 33px;
  letter-spacing: 0.05em;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #004aad;
  color: rgba(0, 0, 0, 0.7);
  @media screen and (max-width: 1024px) {
    font-size: 22px;
    line-height: 26px;
  }
}
.headerText2Desc {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 29px;
  color: #2d3748;
  @media screen and (max-width: 1024px) {
    font-size: 14px;
    line-height: 18px;
  }
}
.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
  > .v-input__control
  > .v-input__slot {
  box-shadow: none !important;
  border: 1px solid #bdbdbd !important;
  box-sizing: border-box;
  border-radius: 4px !important;
  min-height: 57px !important;
  filter: drop-shadow(0px 4px 20px rgba(63, 51, 86, 0.09)) !important;

  @media screen and (max-width: 1366px) {
    min-height: 47px !important;
  }
}
.v-text-field input {
  font-family: "Inter", sans-serif !important;
  font-style: normal;
  font-weight: normal;
  font-size: 16px !important;
  line-height: 22px !important;
  color: #4f4f4f !important;
  padding: 0 !important;
}
.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
  > .v-input__control
  > .v-input__slot:hover {
  border: 1px solid initial !important;
}

.theme--light.v-text-field--solo > .v-input__control > .v-input__slot {
  background: linear-gradient(0deg, #ffffff, #ffffff);
  border: 1px solid #fefefe !important;
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: none !important;

  &:before {
    display: none;
  }
}
//
//.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
//  border: 1px solid #FEFEFE !important;
//  min-height: 52px !important;
//};

//
//.v-input--is-disabled.v-text-field--solo .v-input__slot {
//  background: #F7F9FC !important;
//  border: 1px solid #FEFEFE !important;
//  box-sizing: border-box !important;
//  border-radius: 8px !important;
//}

.v-btn--is-elevated {
  box-shadow: none;
}
.v-btn::before {
  background-color: transparent;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield !important;
}
.text input::placeholder {
  font-family: DM Sans, serif;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 18px !important;
  line-height: 23px !important;
  padding: 20px !important;
  color: #001343 !important;
}

.v-overlay__scrim {
  background-color: #003d67 !important;
  opacity: 0.47;
  border-color: #003d67 !important;
  //will-change: initial !important;
}

.v-text-field.v-text-field--solo .v-label {
  top: calc(50% - 12px) !important;
  /* top: initial; */
}
</style>
