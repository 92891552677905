export default {
    seats: state => state.seats,
    oneWayBookingMinutesTimer: state => state.oneWayBookingMinutesTimer,
    oneWayBookingShowTimer: state => state.oneWayBookingShowTimer,
    oneWayTotalAmount: state => state.oneWayTotalAmount,
    tripAndTravellerDetails: state => state.tripAndTravellerDetails,
    pendingTrips: state => state.pendingTrips,
    oneWayTrips: state => state.oneWayTrips,
    userSelectedTrip: state => state.userSelectedTrip,
    singleBooking: state => state.singleBooking
}