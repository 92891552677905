import {
    reserveSeats,
    unReserveSeats
} from "@/services/api/APIService";
export default {
    ADD_SEAT: (state, value) => {
        let minusFromTimer = JSON.parse(sessionStorage.getItem('minusFromTimer'))
        let userSelectedTrip = JSON.parse(sessionStorage.getItem("userSelectedTrip"))
        let currentTripId = sessionStorage.getItem("currentTripId")
        let seats = []
        let data = {}

        state.seats = [...state.seats, value]
        state.seats.forEach(seat => seats.push(seat))
        data.tripId = userSelectedTrip && userSelectedTrip.id ? userSelectedTrip.id : currentTripId
        data.selectedSeats = seats
        // console.log(data)
        sessionStorage.setItem("currentSelectedSeats", JSON.stringify(state.seats))
        reserveSeats(data).then(() => {
            if (!minusFromTimer || state.seats.length <= 0) {
                // window.location.reload()
                // console.log("")
            }
        }).catch(err => {
            console.log(err.response)
            state.seats = state.seats.filter(seat => seat.seatNumber !== value.seatNumber)
            sessionStorage.setItem("currentSelectedSeats", JSON.stringify(state.seats))
            // window.location.reload()
            //
        })
    },
    REMOVE_SEAT: (state, value) => {
        let userSelectedTrip = JSON.parse(sessionStorage.getItem("userSelectedTrip"))
        let currentTripId = sessionStorage.getItem("currentTripId")
        let seats = []
        let data = {}
        state.seats = state.seats.filter(seat => seat.seatNumber !== value.seatNumber)
        seats.push(value)
        data.tripId = userSelectedTrip && userSelectedTrip.id ? userSelectedTrip.id : currentTripId
        data.selectedSeats = seats
        sessionStorage.setItem("currentSelectedSeats", JSON.stringify(state.seats))
        unReserveSeats(data).then(() => {

        }).catch(err => {
            console.log(err)
        })
    },
    SET_ONE_WAY_BOOKING_MINUTES_TIMER: (state, minutesTimer) => {
        state.oneWayBookingMinutesTimer = minutesTimer
    },

    SET_ONE_WAY_BOOKING_SHOW_TIMER: (state, showTimer) => {
        state.oneWayBookingShowTimer = showTimer
    },
    CLEAR_SEATS: (state) => {
        state.seats = []
        state.oneWayTotalAmount = 0
        sessionStorage.removeItem('oneWayBookingMinutesTimer')
        sessionStorage.removeItem('oneWayBookingShowTimer')
        sessionStorage.removeItem('currentSelectedSeats')
        sessionStorage.removeItem("passengerDetail")
    },
    UPDATE_CURRENT_SEATS: (state, value) => {
        state.seats = value
    },

    GET_TOTAL_AMOUNT: (state, value) => {
        let uniqueSeats = new Set()
        state.seats.forEach(seat => uniqueSeats.add(JSON.stringify(seat)))
        // console.log(uniqueSeats.size)
        if (state.userSelectedTrip.lifeGuard) {
            state.oneWayTotalAmount = (uniqueSeats.size * value) + (uniqueSeats.size * state.userSelectedTrip.lifeGuardCharge)
        } else {
            state.oneWayTotalAmount = uniqueSeats.size * value
        }
    },
    UPDATE_TRIP_AND_TRAVELLER_DETAILS: (state, value) => {
        // console.log(value)
        sessionStorage.setItem("passengerDetail", JSON.stringify(value))
        state.tripAndTravellerDetails = value
    },

    SET_PENDING_TRIPS: (state, value) => {
        state.pendingTrips = value
    },
    SET_ONE_WAY_TRIPS: (state, value) => {
        state.oneWayTrips = value
    },
    SET_USER_SELECTED_TRIP: (state, value) => {
        state.userSelectedTrip = value
    },
    SET_SINGLE_BOOKING: (state, value) => {
        sessionStorage.setItem("singleBooking", JSON.stringify(value))
        state.singleBooking = value
    }
};